import { BehaviorSubject, Observable, filter, map } from "rxjs";
import { environment } from "../../../environments/environment";
import { Inject, Injectable } from "../../di";
import { VeggaResponse } from "../../http/veggaResponse";
import { handleResponse } from "../common.facade";
import { HttpClient, RequestParams } from "../../http";
import { VeggaFacade } from "../vegga-facade";
import { VeggaLoader } from "../../http/veggaLoader";
import { LegacyDevice, LegacyDeviceById, LegacyDeviceByList } from "./devices.interface";

export enum DEVICES {
  A_4000 = 2,
  A_2500 = 3,
  A_BIT = 4,
  A_7000 = 5,
  A_5500 = 6,
  A_4500 = 7,
}

@Injectable("devicesFacade")
export class DevicesFacade {
  @Inject("httpClient") private httpClient: HttpClient;
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  private devicesICMResponse = new VeggaResponse<LegacyDevice[]>();
  private deviceICMResponse = new VeggaResponse<LegacyDeviceById>();
  private legacyUnitResponse = new VeggaResponse<LegacyDevice>();
  private currentDeviceSubject = new BehaviorSubject(null);
  private isDeviceSyncActive = new BehaviorSubject(false);
  private loader = new VeggaLoader();

  get globalLoader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get isLoading$() {
    return this.loader.isLoading$;
  }

  /**
   * Legacy units/devices response, fetched from old agronic api
   */
  get devicesICM(): Observable<any> {
    return this.devicesICMResponse.value$;
  }
  /**
   * Legacy units/devices error, fetched from old agronic api
   */
  get devicesICMError(): Observable<any> {
    return this.devicesICMResponse.error$;
  }
  /**
   * Legacy unit/device response, fetched from old agronic api
   */
  get deviceICM(): Observable<any> {
    return this.deviceICMResponse.value$;
  }
  /**
   * Legacy units/device error, fetched from old agronic api
   */
  get deviceICMError(): Observable<any> {
    return this.deviceICMResponse.error$;
  }

  get currentDevice(): any {
    return this.currentDeviceSubject.getValue();
  }

  get currentDevice$(): any {
    return this.currentDeviceSubject.asObservable().pipe(
      filter((device) => !!device),
      map((device) => {
        if (device.type === DEVICES.A_2500) {
          return { ...device, inoptions: device.inoptions[0] };
        }
        return device;
      })
    );
  }

  set currentDevice(device: any) {
    this.currentDeviceSubject.next(device);
  }

  get currentDeviceSyncStatus$(): Observable<boolean> {
    return this.isDeviceSyncActive.asObservable();
  }

  set currentDeviceSyncStatus(isActive: boolean) {
    this.isDeviceSyncActive.next(isActive);
  }

  get legacyUnit$(): Observable<LegacyDevice> {
    return this.legacyUnitResponse.value$;
  }

  /**
   * Legacy method that points to old agronic units/devices endpoint
   * as BE is still in development.
   * TODO: Implement method with new device/unit endpoints
   */
  loadICMDevices(userId: string, legacyParse = false) {
    const req$ = this.httpClient.get<any>(`${environment.AGRONICWEB_API_URL}/api/v1/users/${userId}/units`, {});

    const subscription = handleResponse(req$, this.devicesICMResponse).subscribe({
      next: (devices) => {

        const parsedDevices = 
        this.parseUnits(devices).map((unit: LegacyDevice) => ({ ...unit.device, lvl: unit.level }))
        this.devicesICMResponse.set(parsedDevices as any);
      },
      error: (err) => {
        this.devicesICMResponse.setError(err, {});
      },
    });

    this.globalLoader.waitFor(subscription);
    this.loader.waitFor(subscription);
  }

  /**
   * Legacy method that points to old agronic units/devices endpoint
   * as BE is still in development.
   * TODO: Implement method with new device/unit endpoints
   */
  loadICMDeviceById(deviceId: string, params: RequestParams = {}) {
    const req$ = this.httpClient.get<any>(`${environment.AGRONICWEB_API_URL}/api/v1/units/${deviceId}`, { params });

    const subscription = req$.subscribe({
      next: (device: LegacyDeviceById) => {
        this.deviceICMResponse.set(device);

        if(this.devicesICMResponse.value) {
          const listDevice = this.devicesICMResponse.value.find((device) => device.id === +deviceId);
          const parsedDevice = {...device, inoptions: Array.isArray(device.inoptions) ? device.inoptions[0] : device.inoptions};
          this.legacyUnitResponse.set({ ...parsedDevice, ...listDevice });
        }
      },
      error: (err) => {
        this.deviceICMResponse.setError(err, {});
      },
    });

    this.globalLoader.waitFor(subscription);
    this.loader.waitFor(subscription);
  }

  /**
   * Legacy method that executes business logic to extract
   * device/unit status
   */
  private checkUnitStatus(obj) {
    if (obj.ram !== undefined) {
      if (obj.connect) {
        if (obj.ram.systemStopMalfunction) {
          obj.status = "systemStop";
        } /*"Paro Sistema"*/ else if (obj.ram.outService) {
          obj.status = "outService";
        } /*"Fuera de servicio"*/ else if (obj.ram.generalMalfunction) {
          obj.status = "generalMalfunction";
        } /*"Avería General"*/ else if (obj.ram.flowMalfunction) {
          obj.status = "flowMalfunction";
        } /*"Avería Caudal"*/ else if (obj.ram.counterMalfunction) {
          obj.status = "counterMalfunction";
        } /*"Avería Contador"*/ else if (obj.ram.ferlitzerMalfunction) {
          obj.status = "ferlitzerMalfunction";
        } /*"Avería Fertilizante sin control"*/ else if (obj.ram.filterMalfunction) {
          obj.status = "filterMalfunction";
        } /*"Avería Filtros sin control"*/ else if (obj.ram.phMalfunction) {
          obj.status = "phMalfunction";
        } /*"Avería regulación PH"*/ else if (obj.ram.ceMalfunction) {
          obj.status = "ceMalfunction";
        } /*"Avería control conductividad"*/ else if (obj.ram.definitiveStopMalfunction) {
          obj.status = "definitiveStopMalfunction";
        } /*"Paro Definitivo"*/ else {
          obj.status = "ok";
        }
      } else {
        obj.status = "notconnected";
      }
    } else {
      if (obj.connect) {
        if (obj.systemStopMalfunction) {
          obj.status = "systemStop";
        } /*"Paro Sistema"*/ else if (obj.outService) {
          obj.status = "outService";
        } /*"Fuera de servicio"*/ else if (obj.generalMalfunction) {
          obj.status = "generalMalfunction";
        } /*"Avería General"*/ else if (obj.flowMalfunction) {
          obj.status = "flowMalfunction";
        } /*"Avería Caudal"*/ else if (obj.counterMalfunction) {
          obj.status = "counterMalfunction";
        } /*"Avería Contador"*/ else if (obj.ferlitzerMalfunction) {
          obj.status = "ferlitzerMalfunction";
        } /*"Avería Fertilizante sin control"*/ else if (obj.filterMalfunction) {
          obj.status = "filterMalfunction";
        } /*"Avería Filtros sin control"*/ else if (obj.phMalfunction) {
          obj.status = "phMalfunction";
        } /*"Avería regulación PH"*/ else if (obj.ceMalfunction) {
          obj.status = "ceMalfunction";
        } /*"Avería control conductividad"*/ else if (obj.definitiveStopMalfunction) {
          obj.status = "definitiveStopMalfunction";
        } /*"Paro Definitivo"*/ else {
          obj.status = "ok";
        }
      } else {
        obj.status = "notconnected";
      }
    }

    // checkStatusImg(obj);
  }

  // private checkNotifications(obj) {
  //   var malfunctions = _.pickBy(obj, function (value, key) {
  //     return _.endsWith(key, 'Malfunction');
  //   });
  //   obj.malfunctions = [];
  //   if (!_.isEmpty(malfunctions)) {
  //     _.forEach(malfunctions, (v, k) => {
  //       if (v) {
  //         var el = {};
  //         el[obj.name] = k;
  //         // notifications.push(el);
  //         obj.malfunctions.push(el);
  //       }
  //     });
  //   }
  // }

  private parseUnits(units) {
    units.forEach((unit) => {
      this.checkUnitStatus(unit.device);
      // this.checkNotifications(unit.device);
      unit.device.lvl = unit.level;
    });
    // showNotification(notifications[0]);
    return units;
  }

  // private checkNotifications(obj) {
  //   var malfunctions = _.pickBy(obj, function (value, key) {
  //     return _.endsWith(key, 'Malfunction');
  //   });
  //   obj.malfunctions = [];
  //   if (!_.isEmpty(malfunctions)) {
  //     _.forEach(malfunctions, (v, k) => {
  //       if (v) {
  //         var el = {};
  //         el[obj.name] = k;
  //         // notifications.push(el);
  //         obj.malfunctions.push(el);
  //       }
  //     });
  //   }
  // }
}
