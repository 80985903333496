import { MapItem, MapMarkerItem, MapsApi } from "@vegga-api-clients/irrigation-control-service";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Injectable } from "../../di";
import { VeggaResponse } from "../../http/veggaResponse";
import { handleResponse } from "../common.facade";

@Injectable("mapsFacade")
export class MapsFacade {
  private markersResponse = new VeggaResponse<MapMarkerItem[]>();
  private mapsResponse = new VeggaResponse<MapMarkerItem[]>();
  private mapsApi: MapsApi;

  get markers$(): Observable<MapMarkerItem[]> {
    return this.markersResponse.value$;
  }

  get markersError$(): Observable<any> {
    return this.markersResponse.error$;
  }

  get markers(): MapMarkerItem[] {
    return this.markersResponse.value;
  }

  get maps$(): Observable<MapMarkerItem[]> {
    return this.mapsResponse.value$;
  }

  get mapsError$(): Observable<any> {
    return this.mapsResponse.error$;
  }

  get maps(): MapItem[] {
    return this.mapsResponse.value;
  }
  constructor() {
    this.mapsApi = new MapsApi();
    this.mapsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  loadMaps() {
    const req$ = this.mapsApi.getMaps();

    handleResponse(req$, this.mapsResponse).subscribe({
      next: (maps) => {
        this.mapsResponse.set(maps);
      },
      error: (err) => {
        this.mapsResponse.setError(err, {});
      },
    });
  }

  loadMarkers(mapId: string) {
    const req$ = this.mapsApi.getMapMarkers({ id: mapId });

    handleResponse(req$, this.markersResponse).subscribe({
      next: (markers) => {
        this.markersResponse.set(markers);
      },
      error: (err) => {
        this.markersResponse.setError(err, {});
      },
    });
  }

  deleteMarker(markerId: string): void {
    this.markersResponse.set(this.markers.filter((marker: MapMarkerItem) => marker.id !== markerId));
  }

  addMarker(marker: MapMarkerItem): void {
    this.markersResponse.set([...this.markers, marker]);
  }

  deleteMap(mapId: string): void {
    this.mapsResponse.set(this.maps.filter((map: MapItem) => map.id !== mapId));
  }

  addMap(map: MapItem): void {
    this.mapsResponse.set([...this.maps, map]);
  }
}
